
.App {
  text-align: center;

}
.content {
  max-width: 2000px;
  margin:auto;
  
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.button{
  background: gold;
  background: linear-gradient(90deg,   rgba(255,0,0,1) 3%, rgba(255,144,0,1) 12%, rgba(238,255,0,1) 24%, rgba(70,255,0,1) 36%, rgba(0,237,255,1) 48%, rgba(0,37,255,1) 60%, 
  rgba(137,0,255,1) 72%, rgba(255,0,132,1) 84%, rgba(255,0,0,1) 97%, rgba(255,0,250,1) 842%);
background-color: gold;
border-radius: 50%;
color:azure;
margin-left:100px;
animation: slide-right 5s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
}

.space{
  margin-left:10px
}
.nyan-cat:link,
.nyan-cat:visited {
text-transform: uppercase;
text-decoration: none;
padding: 15px 40px;
display: inline-block;
border-radius: 100px;
transition: all .2s;
position: relative;
}
.cat:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0,0 ,0,.2 );
}
.cat:active {
    
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0,0 ,0,.2 );
}

.cat-white {
    background-color: #fff;
    color: #777;
}

.cat::after{
    content:'';
    display: inline-block;
    height: 100%;
    width: 100%;
    position: absolute;
    top:0;
    left:0;
    z-index: -1;
    transition: all .4s;
}

.cat-white::after{
    background-color:  white;
}

.cat:hover::after{
    transform: scaleX(2.4) scaleY(2.75);
    opacity: 0;
}
.nyan-cat {
  position: absolute;
  width: 100px;
  height: 56px;
  border-radius:25;
  
  background: url('https://test.austinlee.us/image/sword.png') no-repeat;
  background-size: contain;
}

@keyframes move {
  0% {
      transform: translate(0);
  }
  100% {
      transform: translate(calc(100vw - 100px), calc(100vh - 56px));
  }
}



/* ----------------------------------------------
 * Generated by Animista on 2024-7-15 11:18:52
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-right
 * ----------------------------------------
 */

@keyframes slide-right {
  0%{
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  50%{
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
100%{
  -webkit-transform: translateX(100px);
  transform: translateX(100px);
}
}

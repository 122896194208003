#bull{
    border-radius:50%;
    width:5px;
    height:5px;
    background-color:black;
    display:flex;
    flex-wrap: nowrap;
margin-right: 5px;
  }

.text{
  color:#fff;
}

.btn:link,
.btn:visited {
text-transform: uppercase;
text-decoration: none;
padding: 15px 40px;
display: inline-block;
border-radius: 100px;
transition: all .2s;
position: relative;
}
.btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0,0 ,0,.2 );
}
.btn:active {
    
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0,0 ,0,.2 );
}

.btn-white {
    background-color: #fff;
    color: #777;
}

.btn::after{
    content:'';
    display: inline-block;
    height: 100%;
    width: 100%;
    position: absolute;
    top:0;
    left:0;
    z-index: -1;
    transition: all .4s;
}

.btn-white::after{
    background-color:  red;
}

.btn:hover::after{
    transform: scaleX(2.4) scaleY(2.75);
    opacity: 0;
    background-color: green;
}
/* @keyframes shake {
  0% {
    transform: scaleX(500);
  }
  100% {
      transform: scaleX(-500);
    }
}
.button {
  animation-name: shake;
  animation-duration: 0.1;
  animation-timing-function: ease-out;

}*/ 
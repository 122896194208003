.space{
    margin-left:10px
}
@keyframes shake {
    0% {
      transform: scaleX(500);
    }
    100% {
        transform: scaleX(-500);
      }
  }
  .button {
    background: gold;
    background: linear-gradient(90deg,   rgba(255,0,0,1) 3%, rgba(255,144,0,1) 12%, rgba(238,255,0,1) 24%, rgba(70,255,0,1) 36%, rgba(0,237,255,1) 48%, rgba(0,37,255,1) 60%, 
    rgba(137,0,255,1) 72%, rgba(255,0,132,1) 84%, rgba(255,0,0,1) 97%, rgba(255,0,250,1) 842%);
    background-color: gold;
    color:azure;
    left:100px;
    border-radius: 50%;
    -webkit-animation: slide-right 5s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
    animation: slide-right 5s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
  }